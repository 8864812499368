div.menu {
  background: var(--neutral-2);
  color: var(--color-primary);
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

.toolBar {
  padding-top: var(--spacing-52);
  padding-bottom: var(--spacing-32);
  padding-inline: var(--spacing-xs);
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: var(--spacing-xs);
  align-items: center;
  --svg-color: var(--color-white);

  @media (--small) {
    padding: var(--spacing-sm) var(--spacing-xs);
  }

  & a {
    & svg {
      height: var(--spacing-24);
      width: auto;
    }
  }

  & .closeModal {
    background-color: var(--neutral-2);
    padding: 0;
    min-width: var(--spacing-24);
    width: var(--spacing-24);
    height: var(--spacing-24);

    & svg {
      --svg-color: var(--color-primary);
      width: var(--spacing-24);
      height: var(--spacing-24);
    }
  }
}
