.root {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--gap, var(--spacing-2xs));
  cursor: pointer;
  @extend %typo-caption1;

  & input {
    position: absolute;
    opacity: 0;
    height: 1px;
    width: 1px;
  }

  & .indicator {
    display: block;
    flex-shrink: 0;
    width: var(--spacing-24);
    height: var(--spacing-24);
    border: 1px solid var(--color-divider);
    position: relative;
  }

  &:has(input:focus-visible) {
    & .indicator {
      outline: 1px solid var(--color-divider);
    }
  }

  &:has(input:checked) {
    & .indicator {
      outline: 1px solid var(--color-divider);
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        content: "";
        display: block;
        background: url("/images/ui-checked-mark.svg") no-repeat;
        background-size: var(--spacing-16);
        width: var(--spacing-16);
        height: var(--spacing-16);
      }
    }
  }

  &:has(input:disabled) {
    color: var(--color-text-secondary);
    cursor: not-allowed;
    & .indicator {
      border-color: var(--color-divider-inactive);
      outline-color: var(--color-divider-inactive);
    }
  }
}
