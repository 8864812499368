.root {
  display: flex;
  gap: var(--spacing-8);
  align-items: center;
}

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: var(--spacing-12);
  margin-bottom: var(--spacing-8);

  @media (--small) {
    display: block;
    margin-top: var(--spacing-20);
  }
}

.modal {
  color: var(--color-dark);
  background: var(--neutral-10);

  & span {
    @extend %typo-subtitle2;
  }

  &__content {
    height: 100%;
    padding: var(--spacing-40) var(--page-inset);
    background: var(--neutral-10);
  }
}

.listItem {
  cursor: pointer;
  margin: var(--spacing-8) 0;

  &:hover {
    text-decoration: underline;
  }

  &__selected {
    font-weight: bold;
  }
}

.label {
  @extend %typo-body2;
  text-transform: uppercase;
}

.section {
  margin-bottom: var(--spacing-24);
  padding-bottom: var(--spacing-24);
}

.langButton,
.langButton:hover:not(:disabled) {
  color: var(--color-white);

  &::before,
  &::after {
    background: var(--color-white);
  }
}
