.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  & .error {
    @extend %typo-caption1;
    color: var(--color-red);

    & a {
      color: var(--color-red);
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  & .inputs > * {
    &:not(:first-child) {
      margin-top: var(--spacing-md);
    }
  }

  & .confirmation {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & a {
      @extend %typo-caption1;
      color: var(--color-text);
      text-underline-offset: 2px;
    }
  }

  & .inputRow {
    display: flex;
    gap: var(--spacing-sm);
    flex-direction: column;
    @media (--small) {
      flex-direction: row;
      align-items: center;
    }
  }
}

.intro {
  margin-bottom: var(--spacing-md);
  & h2 {
    @extend %typo-subtitle1;
    margin-bottom: var(--spacing-4);
  }

  & p {
    @extend %typo-body2;
  }
}

.button {
  --button-max-width: 365px;
  width: 100%;
  @media (--small) {
    max-width: var(--button-max-width);
  }
}

/* Style overrides when form is in modal */
.accountModal {
  & .button {
    max-width: 100%;
  }

  & .form {
    margin-bottom: var(--spacing-lg);
  }
}

.loginPage {
  --inner-section-width: 534px;
  max-width: var(--inner-section-width);
  margin-inline: auto;
  padding-block: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.modal {
  padding-inline: var(--spacing-sm);
}
